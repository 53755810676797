body {
  margin: 0;
  font-family: 'Roboto', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff!important;
}
.MuiDrawer-root{

}
/*fbfbfb
f8f9fa
eef4fb
eef4fb
*/
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiAppBar-positionStatic{
  background-color: #fff!important;
  color:#424242!important;
}
.MuiAccordionSummary-root{
  background-color: #fff!important;
}
.MuiButtonBase-root, .MuiInputBase-root, .swal2-confirm, .swal2-cancel, .swal2-ok, .PrivateSwitchBase-input-22{
  border-radius: 0px!important;
  

}
.MuiFab-primary{
  border-radius: 50%!important;
}
.MuiTableRow-root{
  cursor:pointer !important;
}
.MuiFormHelperText-root{
  font-size: 9px!important;
}
.em-breve{
  padding-left: 5px!important;
  width: 100px!important;
  border-radius: 5px!important;
}