
#div-dashboard-container{

    padding: 5px;
    margin-top: 20px;
    display: block;
    
}
#dashboard-container-cards-coletas{
    display: flex;
    justify-content: space-around;
    width: 100%;
}
#dashboard-container-cards-coletas .card-coletas{
    width: 15%;


}
.card-coleta-item{
    display: flex;
}
.card-coleta-item svg{
    width: 100%;
    height: 100%;
}
.card-info{
    background-color: #311B92;
    color:#fff;
}