.gestao-chamado-main{
    font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
}
.gestao-chamado-nav{
    height: 45px;
    width: 100%;
    padding-top: 8px;
    background-color: #263238;
    position: fixed;
    left: 0px;
    top: 0px;
    display: flex;
    z-index: 99999999999999999;
    
}
.font-10{
    font-size: 10px!important;
}

#div-container-full{
    display: flex;
}
#gestao-chamados-container{
    margin-top: 45px;
    padding-top: 3px;
    
    background-color: #000;
    display:  flex;
    flex-wrap: wrap;
    padding-left: 1px;
    padding-right: 1px;
    overflow-x: hidden;
    align-items: flex-start;
    font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;   
}


.MuiBadge-badge{
    font-size: 9px!important;
    width: 16px!important;
    height: 16px!important;
    border-radius: 8px!important;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    padding: 1px!important;
}

#div-container-fila{
    position: fixed;
    left: 0px;
    top: 45px;
    width: 100%;
    height: 100vh;
    background-color: #000;
    opacity: 0.8;
    z-index: 99999999;
    display: none;    
}
.div-container-fila-show{
    display: flex;
    justify-content: center;
}


@media (max-width:900px) { 
    .janela-container-main{
        width: 33%;
        padding-left: 0px;
        padding-right: 0px;
        min-height: 100px;
        
        margin-top: 1px;       
    }
}


@media (max-width:1366px) { 
    .janela-container-main{
        width: 25%;
        padding-left: 0px;
        padding-right: 0px;
        min-height: 100px;
        
        margin-top: 1px;       
    }
}

@media (min-width:1920px) { 
    .janela-container-main{
        width: 20%;
        padding-left: 0px;
        padding-right: 0px;
        min-height: 100px;
     
        margin-top: 1px;       
    }
}

.col-triagem{
    background-color: #E3F2FD!important;
}
.col-caminho{
    background-color: #64B5F6!important;
}
.col-atendendo{
    background-color: #1976D2!important;
    color:#fff!important;
}
.col-andamento{
    background-color: #BBDEFB!important;
    color:#000!important;
}
.col-pecas{
    background-color: #EF9A9A!important;
    color:#fff!important;
}
.col-finalizado{
    background-color: #26A69A!important;
    color:#fff!important;
}
.col-retorno{
    background-color: #FFCA28!important;
    color:#000!important;
}
.col-orcamento{
    background-color: #FF8F00!important;
    color:#000!important;
}
.col-concluida{
    background-color: #00838F!important;
    color:#fff!important;
}
.col-parcial{
    background-color: #B71C1C!important;
    color:#000!important;
}
.col-cancelada{
    background-color: #ECEFF1!important;
    color:#fff!important;
}
.pointer{
    cursor: pointer;
}

.janela-selecionada{
    opacity: 0.3;
    cursor: not-allowed;
}

.rotulo-empty-data{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
 
    font-size: 9px;
    color:#888;
}

.janela-container-title{
    width: 100%;
    height: 22px;
    display: flex;
    font-size: 10px;
    color:#fff;
    padding-top: 1px;
    justify-content: center;
    align-items: center;
}
.janela-container-title svg{
    height: 20px!important;
    width: 20px!important;
}
.janela-title-loading{
    width: 5%; 
}
.loading-icon:hover{
    cursor: pointer;
    color : #81D4FA;
}

.loader {
    color : #81D4FA;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.icon-48{
    width: 26px!important;
    height: 26px!important;
    color : #81D4FA!important;
}
.janela-title-name{
    width: 40%;
    font-size: 14px;
}

.janela-title-options{
    width: 55%;
    display: flex;
    justify-content: space-between;
    padding-right: 0px;
}
.janela-title-options .btn-danger{
    background-color: red;
    font-size: 9px!important;
    padding-top: 1px;
    padding-bottom: 1px;
    border: none;
    width: 53%;
    border-radius: 2px!important;
}
.janela-title-options .btn-success{
    background-color: green!important;
    font-size: 9px!important;
    padding-top: 1px!important;
    padding-bottom: 1px!important;
    border: none!important;
    color:#fff!important;
    width: 45%;
    border-radius: 2px!important;
}

.janela-title-options .itens{
    width: 25%;
    height: 100%;
    text-align: center;
    padding:0px;
}

.janela-title-options .itens:hover{
    color : #AED581;
    cursor: pointer;
}

.janela-title-options .itens svg{
    height: 100%;
}

#div-overlay-botao{
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 400px;
    height: 65px;
    display:  flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5px;
    padding-left: 5px;
}

.divOculta{
    display: none;
}

.divChamadoSelecionado{
    position: absolute;
    width: 350px;
    background-color: #fff;
    border-radius: 5px;
    padding:5px;
    top: 10px;
    left: 10px;
    z-index: 999999999;
    display: block;
    box-shadow: 0px 0px 25px #000;
    cursor: pointer;
}

.chamado-linha-flex{
    display: flex;
    font-size: 10px;
}
.floating .chamado-linha-flex{
    border:  dashed 1px #444;
    background-color: #000!important;
    color: #fff!important;
}
.fabExcluir{
    position: fixed;
    left: 45px!important;
    bottom: 35px!important;
    text-align: center;
    height: 60px;
    width: 60px;
}
.dragged .chamado-linha-flex{
    opacity: 0.5;
}

.linha-flex-chamado{
    width: 16%!important;
    padding-left: 2px;
    padding-right: 2px;
    text-align: right;
}

.linha-flex-data{
    width: 20%!important;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
}

.linha-flex-empresa{
    width: 52%!important;
    padding-left: 2px;
    padding-right: 2px;
    text-align: left;
}

.linha-flex-progress{
    width: 17%!important;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
    padding-top: 2px;
}

.icon-min-linha{
    margin-top: -5px!important;
    color:#f00!important;
    height: 17px!important;
    width: 17px!important;
}
.linha-flex-icone{
    width: 8%!important;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
}

.progress-container-main{
    width: 100%;
    height: 6px;
    background-color: #ccc;
    margin-top: 3px;
    padding:1px;
}

.status2{
    color: aqua!important;
    background-color: transparent;
}

.status2:hover{
    color: #000!important;
    background-color: aqua!important;
    cursor: pointer;
}

.status3{
    color: #1E88E5;
    background-color: transparent;
}

.status3:hover{
    color: #fff!important;
    background-color: #0D47A1!important;
    cursor: pointer;
}

.status4{
    color: #fff;
    background-color: transparent;
}

.status4:hover{
    color: #000!important;
    background-color: #fff!important;
    cursor: pointer;
}

.status5{
    color: #fff;
    background-color: #D50000;
}

.status5:hover{
    color: #fff!important;
    background-color: #0D47A1!important;
    cursor: pointer;
}

.status6{
    color: #43A047;
    background-color: transparent;
}

.status6:hover{
    color: #fff!important;
    background-color: #43A047!important;
    cursor: pointer;
}

.status7{
    color: #FF1744;
    background-color: transparent;
}

.status7:hover{
    color: #fff!important;
    background-color: #FF1744!important;
    cursor: pointer;
}

.status10{
    color: #fff;
    background-color: #D50000;
}

.status10:hover{
    color: #fff!important;
    background-color: #0D47A1!important;
    cursor: pointer;
}

.status10 .icon-min-linha{
    color: #fff!important;
}

.rotulo-install{
    
    padding : 0px!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
    border-radius: 2px;
    font-size: 7px;
    min-width: 80%;
    text-align: center;
    background-color: #80CBC4;
    border: solid 1px #009688;
    color:#000!important;
}

.rotulo-retirada{
    
    padding : 0px!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
    border-radius: 2px;
    font-size: 7px;
   min-width: 80%;
    text-align: center;
    background-color: #EF9A9A;
    border: solid 1px #C62828;
    color:#C62828!important;
}

.rotulo-preventiva{
    
    padding : 0px!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
    border-radius: 2px;
    font-size: 7px;
   min-width: 80%;
    text-align: center;
    background-color: #64B5F6;
    color:#0D47A1!important;
    border:solid 1px #0D47A1;
}
.rotulo-garantia{
    
    padding : 0px!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
    border-radius: 2px;
    font-size: 7px;
   min-width: 80%;
    text-align: center;
    background-color: #FFF59D;
    color:#000!important;
    border:solid 1px #FBC02D;
}


.div-sem-info{
    color : #555!important;
    margin-top: 10px;
    height: 90vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.texto-protegido {
    -webkit-user-select: none;  
   -moz-user-select: none;     
   -ms-user-select: none;      
   user-select: none;  
 } 

 .status8{
    -webkit-animation: status8 .90s linear infinite;
    -moz-animation: status8 .90s linear infinite;
    -ms-animation: status8 .90s linear infinite;
    -o-animation: status8 .90s linear infinite;
    animation: status8 .90s linear infinite;
 }
 @keyframes status8 {
    0% { color: #282828;}

    100% {  color:lime;  }
}

@keyframes blinkIcon {
    0% { color: #282828;color:#fff }

    100% { color:  lime; color:#000;  }
}

.blinkIcon {
  -webkit-animation: blinkIcon .90s linear infinite;
  -moz-animation: blinkIcon .90s linear infinite;
  -ms-animation: blinkIcon .90s linear infinite;
  -o-animation: blinkIcon .90s linear infinite;
  animation: blinkIcon .90s linear infinite;
}

.janela-area-chamados{
    color:#fff;
    padding-top: 3px;
}

.movendo-chamado:hover .janela-area-chamados{
   opacity: 0.5;
}

.janela-area-drop{
    width: 100%;
    display: flex;
    width: 100%;
    margin-top: 10px;
    justify-content: center;
}

.janela-area-drop-icone{
    width: 50%;
    height: 25%;
    border-radius: 5px;
    text-align: center;
}

.janela-area-drop-icone:hover{

   
}

.janela-area-drop svg{
    width: 32px;
    height: 32px;
    color: #fff;
}

.movendo-chamado:hover .janela-area-drop{
    display: flex!important;
}

