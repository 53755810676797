

#nav-container-main{
    height: 120px;  
    display: flex;
    color : #fff;
    background-color: transparent!important;
    position: absolute;
    z-index: 999;
    width: 100%;
    
}
#nav-container-logo{
    height: 100%;
    padding-left: 2%;
    padding-right: 2%;
    width: 30%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-color: transparent!important;
}

#nav-container-menu{
    height: 100%;
    padding-left: 5%;
    padding-right: 5%;
    width: 53%;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-right: 2%;
    background-color: transparent!important;
}
#nav-container-menu-itens{
    width: 100%;
    display: flex;
    height: 40px;
    justify-content: space-around;
}

#nav-container-menu-itens h6:hover{
    text-shadow: 0px 0px 50px #fff;
    cursor: pointer;
    font-weight: bold;
}

#nav-container-social{
    height: 100%;
    width: 12%;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

#nav-container-social-flex{
    display: flex;
    justify-content: space-between;
    width: 100%;  
}

.div-container-social{
    width: 38px;
    height: 38px;
 
    border-radius: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.div-container-social:hover{
   background-color: #fff;
   cursor: pointer;
   box-shadow: 0px 0px 25px #fff;
}

.div-container-social:hover svg{
    color:#000!important;
}


.carousel-caption{
    top: 0px!important;
    width: 100%;
    height: 100%!important;
    left: 0px!important;
    background-image: url("./Componentes/Imagens/pixel.png")!important; 
    text-shadow: 0px 0px 20px #000!important;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-text-stroke-width: 1px!important; /* largura da borda */
    -webkit-text-stroke-color: #fff!important; /* cor da borda */
    padding: 10%;

   
}

.carousel-caption p{
    font-size: larger;
}



.carousel-caption button{
    font-size: 18px;
    border-radius: 25px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
} 


.carousel-control-next{
    border: solid 1px fff;
    width: 48px!important;
    height: 48px;
    top: 50%!important;
    right: 90px!important;
    border-radius: 25px;
    
    opacity: 1!important;
}

.carousel-control-next:hover{
    box-shadow: 0px 0px 50px #fff!important;
}


.carousel-control-prev{
    border: solid 1px fff;
    width: 48px!important;
    height: 48px;
    top: 50%!important;
    left: 90px!important;
    border-radius: 25px;
  
    opacity: 1!important;
}
.carousel-control-prev:hover{
    box-shadow: 0px 0px 50px #fff!important;
}



.carousel-indicators li:hover{
    background-color:orange!important;
    
}

#card-container-main{
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 10%;
    padding-top: 50px;
    
}

#card-container-main .card-container{
    width: 30%;
    padding-top: 20px;
    text-align: center;
 
    padding: 25px;
    border-radius: 15px;
  
}


#card-container-main .card-container p, h5{
    margin-top: 20px!important;
}

#card-container-main .card-container p{
    text-align: justify;
}

.card-container-itens{
    width: 100%;
   
    display: flex;
    justify-content:  center!important;

}
#card-container-main .card-container .card-container-avatar{
    background-color: #01579b;
    border-radius: 100px;
    width: 200px;
    height: 200px;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
#card-container-main .card-container .card-container-avatar svg{
    height: 90%;
    width: 90%;
    color : #fff;
}
.card-container-itens{
    text-align: center!important;
   
}

#sobre-container{
    width: 100%;

    

    padding-bottom: 50px;
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
    color:#444;
}
#sobre-container h1{
    font-size: 2.9rem;
}
#sobre-container p{
    margin-top: 20px;
    font-size: 16px;
}
#sobre-container #sobre-container-text{
    text-align: justify;
}

#sobre-container #sobre-container-text ul{
 
    font-size: 14px;
    list-style: none;
}


#div-container-pmc{
    width: 100%;
    height: 250px;
    margin-top: 100px;
    background-color: #ffd54f;
}
#nav-container-menu-itens a{
    color:#fff;
}

#nav-container-menu-itens a:hover{
    color:#fff;
    text-decoration: none;
}

