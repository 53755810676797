
#nav-datacount-side{
    background-color: #fff!important;
    width: 260px;
    min-height: 100%;
}
#nav-datacount-side-title{
    height: 60px;
    background-color: #26363f;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
#nav-datacount-side-logo{
    height: 160px;
    background-color: #f5f5f5;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}
#container-datacount-main{
    width: 100%;
   
    display: block;
    min-height: 100%;
}
#nav-datacount-main{
    background-color: #04141f;
    height: 60px;
    width: 100%!important;
    padding: 5px;
}
#container-full{
  
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    height: 87vh;
    overflow: auto;
  
}

#container-full table{
    background-color: #fff;
}