#container-fleet-map{
    width: 100%!important;
    height: 91vh!important;
}

.div-avatar-veicle{
  font-family:  'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif!important;
  background-color: #311b92;  
  border-radius: 5px;
  padding: 10px;
  color:#fff;
}

.div-circle-velocidade svg{
  width: 48px!important;
  height: 48px!important;
  color:#bbb
}

.ertical-timeline-element-icon{
  border: none!important;
  box-shadow: none!important;
  background-color: #fff!important;
}

.icon-xx{
  width: 48px!important;
  height: 48px!important;
  margin-left: -22px!important;
  margin-top: -25px!important;
  color:#444;
}

.cardPosicao{
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 10px;
  margin-top: 5px;
}

.div-avatar-veicle hr{
  background-color: #fff;
}

#toolbar-maps-fleet{ 
    background-color: #fff;
    padding-left: 50px;
    padding:5px;
    padding-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    z-index: 9999999999999999999999!important;
}

.div-icon-car-popup{
  display: flex;
  flex-direction: column;
}

#toolbar-maps-fleet input{
    background-color: #fff!important;
}

#nav-controles-view{
  z-index: 9999999999999999999999!important;
  position: fixed;
  right: 15px!important;
  top: 90px!important;
  width: 80px;
  height: 60vh;
  background-color: #fff;
  box-shadow: 0px 0px 25px#ccc;
  padding: 5px;
  border-radius: 5px;
}

.mini-icon{
  font-size: 11px;
}

.mini-icon svg{
  width: 18px!important;
  height: 18px!important;
}

.popup-container-main{
  width: 100%;
  padding-left: 0px!important;
  width: 300px!important;
}

.popup-container-status{
  display: flex;
  border-bottom: solid 1px #ccc;
  padding-bottom: 10px;
}

.popup-container-placa{
  text-align: center;
  width: 25%;
  font-weight: bold!important;
  border-right: solid 1px #ccc;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hovered:hover{
  box-shadow: 0px 0px 10px #f5f5f5;
  border: solid 1px #00796b;
}

.popup-container-ignicao{
  text-align: center;
  width: 50%;
  display: flex;
}

.popup-container-movimento{
  text-align: center;
  width: 25%;
  border-left: solid 1px #ccc;
  display: flex;
}

.MuiDrawer-paperAnchorRight{
  background-color: #fff!important;
}

#toolbar-maps-fleet .MuiSlider-thumbColorSecondary{
    background-color: red!important;
}

#toolbar-maps-fleet .MuiSlider-markLabel{
  font-size: 9px!important;
}

#log-pconnect{
    z-index: 9999999999999;
    position: fixed;
    bottom: 10px;
    left: 10px;  
    width: 120px;
}

.div-marker-car{
 margin-top: -17px;
}

.div-marker-car img{
  margin-left: -20px;
}

.custom{
  margin-top: 10px;
}

.card-selecao{  
  background-color: #E8F5E9!important;
}

.card-ponto:hover{
  background-color: #E8F5E9!important;
  cursor: pointer;
}

.input-blank .MuiOutlinedInput-root{
  border: none!important;  
  margin-bottom: 10px;
  border-radius: 50px!important;
}

.input-blank .MuiInputBase-input{
  border: none!important;
  background-color: transparent!important;
}

#progress-map{
  position: fixed;
  top: 0px;
  z-index: 999999999999999999999;
  left: 0px;
  width: 100%;
}



.div-marker-point{
  position: absolute!important;
  background-color: #00f;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  color: #fff;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  margin-left: -5px;
  margin-top:-25px!important;
}

.div-marker-point-move{
  position: absolute;
  background-color:green;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  color: #fff;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  margin-left: -5px;
  margin-top:-25px!important;
}

.centericone-rotulot{
    font-size: 9px!important;
    position: absolute;
    text-align: center;
    min-width: 60px;
    margin-top: -20px;
    margin-left: -35px;
    background-color: #00796b;
    border: solid 1px #004d40;
    color:#fff;
    padding:0px;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 3px ;
  }

  .centericone-rotulof{
    font-size: 9px!important;
    position: absolute;
    min-width: 60px;
    text-align: center;
    margin-top: -20px;
    margin-left: -35px;
    background-color: #b71c1c;
    border: solid 1px #d32f2f;
    color:#fff;
    padding:0px;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 3px ;
    
  }

  .div-pop-map-icon-container{
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    color:#fff;
  }

  .div-pop-map-icon-container svg{
    width: 80%;
    height: 80%;
  }

  .table-fleet svg{
    width: 14px!important;
    height: 14px!important;
    margin-top: -2px;
    padding-right: 2px;
  }

  #drawer-mapa-historico{
    width: 350px;
    position: absolute;
    right: 0px;
    top: 83px;
    background-color: #fff;
    z-index: 99999999999;
    height: 100%;
    padding: 10px;
    box-shadow: -10px 20px 50px #dbdbdb;
  }

  .drawer-mapa-oculto{
    display: none;
  }
  




