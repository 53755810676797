ul{
  list-style: none;
}




.MuiTablePagination-input{
  background-color: transparent!important;
}
.table tbody tr td{
  padding-top: 2px!important;
  padding-bottom: 2px!important;
}

.selectedRow{
 
  border-left: solid 2px #f00!important;
  

}
#div-side-menu .MuiTypography-subtitle2{
  color:#666!important;
}
.selectedSubRow{
  border-bottom: solid 1px #78909C!important;
  border-left: solid 1px #78909C!important;
  border-right: solid 1px #78909C!important;
  padding-bottom: 5px!important;
  background-color: #ECEFF1!important;
  margin-bottom: 5px!important;
  padding-bottom: 10px;
}
.btn-transparente{
  border: none!important;
  border-left: none!important;
}
.Mui-selected{
  background-color: #fdd835!important;
  box-shadow: 0px 0px 15px #f5f5f5;
  text-align: center!important;
}
.Mui-selected .MuiTab-wrapper{
  font-size: 16px!important;
  width: 100%!important;
  text-align: center!important;
  color: #000!important;
  
}
.recharts-default-legend{
  padding-left: 50px!important;
  width: 100%;
  text-align: left!important;
}
.div-menu{
  margin-left: 10px;
  cursor: pointer;
  
}

#nav-container #menu-item-icon{
  color: #b2afaa;
  text-align: center;
}
#nav-container #menu-item-label{
  color: #b2afaa;
  text-align: center;
  font-size: 10px;
}
table{
  background-color: #fff!important;
}
a{
  text-decoration: none!important;
}
.div-form-container{
 
  border-radius: 5px;
  padding-bottom: 50px;
  margin-top: 40px;
  width: 100%;
}

.div-form-container table{
  width: 100%!important;
}

.div-menu-div:hover{
  color:orangered;
}
.MuiMenu-paper{
  margin-top: 35px!important;
}
.MuiListItem-root{
  font-size: 10px!important;
}

#div-principal-header{
  display: flex;
  background-color: #fff!important;
 
  
}

#div-principal-container{
  margin-top: 5px;
  padding: 10px;
  padding-left: 100px!important;
  padding-right: 100px!important;

  overflow-x: hidden;
}
#div-main-flex{
  display: flex;
  margin-top: 0px;
 padding: 0px!important;
 padding-top: 60px!important;
}


.container-button-principal{
  width: 100%;
  height: 60px;
  text-align: center;
  border-radius: 5px;
  margin-top: 2px;
}
.MuiPaper-elevation1{
  box-shadow: none!important;
  border: none!important;
}
.MuiAccordionSummary-expandIcon{
  color:#37474F!important;
}
.MuiAccordionSummary-root , .MuiAccordionSummary-content{
  background-color: #fff!important;
}
.MuiAccordionDetails-root{
  padding-top: 0px!important;
  background-color: #fff!important;
}



.div-hover{
  padding: 5px;
  font-size: 12px!important;
  cursor: pointer;
  margin-top: 4px;
  background-color: #f2f2f2;
  color:#37474F!important;
  
}

.div-hover:hover {
 scale: 1.1;
 color:#FF9800!important;
  font-weight: bold!important;
}
#form-container-area{
  margin-top: 80px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;


  min-height: 100vh;
  background-color: #fff!important;

}
#div-sidebar-main{
  width: 15%;
  padding: 8px;
  left: -2px!important;
  padding-top:  20px;

  border: none!important;
  box-shadow: none!important;
  box-shadow: none!important;
}


.no-background{
  background-color: #fff!important;
  font-size: 12px!important;
}

.no-background .MuiTypography-body1{
  font-size: 10px!important;
}

.div-progressbar{

  margin-top: 5px;
  width: 100%;
  z-index: 99999999999999;
}

#login-container-main{
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

#login-container-form{
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 20px;
  width: 350px;
  height: 250px;
  background-color: #fff;
  box-shadow: 0px 0px 50px #ccc;
}

.janela-elevation{
  margin-top: 10px;
  background-color: #fff;
  padding: 50px;
  border-radius: 5px;

}
.title-menu-hover:hover{
  color:orangered;
}


table, tr, td {
  border: 0px!important; 
  }
  table, tr{
    border-bottom: solid 0.2px #d6d6d6!important; 
    }
.swal2-container{
  z-index: 99999999999!important;
}
.bnt-inativa{
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  border: solid 1px red;
  color:red;
  content: 'INATIVA';
}
.bnt-inativa:hover{
 
  cursor: pointer;
  
}
.bnt-inativa:hover{
  content: 'ATIVAR';
  
}
.bnt-ativa{
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  border: solid 1px #006064;
  color:#006064;
}

.bnt-ativa:hover{

  cursor: pointer;
}
.fabIncluir{
  position: fixed;
  right: 45px!important;
  bottom: 35px!important;
  text-align: center;
}

.fullButton{
  height: 100%!important;
}

.info{
  background-color: #fff!important;
  color:#303f9f!important;
  border:solid 1px #303f9f!important;
}
.info:hover{
  background-color: #303f9f!important;
  color:#fff!important;
  border:solid 1px #303f9f!important;
}

.default{
  background-color: #fff!important;
  color:#444!important;
  border:solid 1px #444!important;
}
.default:hover{
  background-color: #444!important;
  color:#fff!important;
  border:solid 1px #444!important;
}


.danger{
  background-color: #fff!important;
  color:#f44336!important;
  border:solid 1px #f44336!important;
}
.danger:hover{
  background-color: #f44336!important;
  color:#fff!important;
  border:solid 1px #f44336!important;
}

.success{
  background-color: #fff!important;
  color:#00897b!important;
  border:solid 1px #00897b!important;
}
.success:hover{
  background-color: #00897b!important;
  color:#fff!important;
  border:solid 1px #00897b!important;
}
.btn-success{
  
  background-color: #fff!important;
  color:#00897b!important;
  border:solid 1px #00897b!important;
}
.btn-success:hover{
  
  background-color: #00897b!important;
  color:#fff!important;
  border:solid 1px #00897b!important;
  box-shadow: 0px  0px 25px #00897b;
}

.btn-info{
  
  background-color: #fff!important;
  color:#1565c0!important;
  border:solid 1px #1565c0!important;
}
.btn-info:hover{
  
  background-color: #1565c0!important;
  color:#fff!important;
  border:solid 1px #1565c0!important;
  box-shadow: 0px  0px 25px #1565c0;
}
.btn-error{
  
  background-color: #fff!important;
  color:#f44336!important;
  border:solid 1px #f44336!important;
}
.btn-error:hover{
  
  background-color: #f44336!important;
  color:#fff!important;
  border:solid 1px #f44336!important;
  box-shadow: 0px  0px 25px #f44336;
}

.selecionado{
  border: solid 1px #00897b!important;
  color:#00897b!important;
}

.btnAlerta{
  
  color:#f44336!important;
  border:solid 1px #f44336!important;
}

.emBreve .MuiBadge-badge{
  padding-top: 0px!important;
  padding-bottom: 0px!important;
  padding-left: 5px!important;
  padding-right: 5px!important;
  border-radius: 5px!important;
  font-size: 8px!important;
  width: 30px!important;
}

.novo .MuiBadge-badge{
  padding-top: 0px!important;
  padding-bottom: 0px!important;
  padding-left: 5px!important;
  padding-right: 5px!important;
  border-radius: 5px!important;
  font-size: 8px!important;
  width: 30px!important;
  background-color: #00695c!important;
  color: #fff!important;
}

.iconDanger{
  background-color: #fff!important;
  color:#f44336!important;

}
.iconDanger:hover{
  background-color: #f44336!important;
  color:#fff!important;
  cursor: pointer;
}

.iconSuccess{

  color:#00897b!important;

}
.iconSuccess:hover{
  background-color: #00897b!important;
  color:#fff!important;
  cursor: pointer;
}


.form-cadastro .MuiTypography-root{
  color:#444!important;
  font-weight: normal;

}
input{
  color : #444!important;
  
  border: none;
}
input:focus{
  /*box-shadow: 0px 0px 5px #1565c0!important;*/
}
.MuiBreadcrumbs-root{
  margin-left: 6px!important;
}
.divPagination{
  display: flex;
  justify-content: flex-end;
  padding:5px
}
fieldset,  .MuiButton-outlined{
  border : solid 1px #d7d7d7!important;
  
}
.MuiButton-outlined{
  border-left: solid #04141f 2px!important;
  border-radius: 0px!important;

}
input, textarea, select{
  color: #707070!important;
  font-size: 14px!important;
 
 
}

.MuiDialog-root .MuiDialogTitle-root{

}

#form-login .MuiOutlinedInput-input{
  color: #707070!important;
  font-size: 16px!important;
 
}

.MuiAppBar-colorDefault{
  background-color: #fff!important;
}

fieldset {

  color: #000!important;
}
input fieldset:focus {
 background-color: #000!important;
}
 .MuiTab-wrapper span{
  font-size: 9px!important;
}


#customized-dialog-title{
  background-color: #37474f!important;
  color :#fff!important;
}


#vertical-tabpanel-0, #vertical-tabpanel-1, #vertical-tabpanel-2, #vertical-tabpanel-3, #vertical-tabpanel-4, #vertical-tabpanel-5, #vertical-tabpanel-6{
  width: 100%;
}

.statusAtivo{
  color :#00695c!important;
  cursor: pointer;
}
.statusInativo{
  color :#d84315!important;
  cursor: pointer;
}
.div-flex-space{
  display: flex;
  justify-content: space-around;
}


.nivel-toner-container{
  display: flex;
  text-align: center!important;
  padding-bottom: 8px!important;
  padding-bottom: 0px!important;
  padding-top: 0px!important;
  align-items: center;
  justify-content: center;
}

.nivel-toner-container-nivel{
  margin-top: 2px;
}
.nivel-toner-container-rotulo-top{
  margin-top: 0px;
  font-size: 9px;
  min-height: 15px!important;
  width: 100%;
}
.nivel-toner-container-rotulo{
  margin-top: 5px;
  font-size: 9px;
  width: 100%;

}

.nivel-toner-container div{

  text-align: center!important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.div-container-nivel-toner{
  display:  flex;
  justify-content: space-around;
}
.div-container-cirular-nivel{
  width: 20%;
}

.icon-status-coleta{
  font-size: 22px!important;
  color:#b0bec5;
}
.icon-status-coleta1{
  font-size: 22px!important;
  color:#00695c;
}
.icon-status-coleta2{
  font-size: 22px!important;
  color:#00695c;
}
.icon-status-coleta3{
  font-size: 22px!important;
  color:#ff9100;
}
.icon-status-coleta4{
  font-size: 22px!important;
  color:#00695c;
}
.icon-status-coleta5{
  font-size: 22px!important;
  color:#bf360c;

}


.icon-status-coleta6{
  font-size: 22px!important;
  color:#ff6f00;

}

.icon-status-coleta7{
  font-size: 22px!important;
  color:#bf360c;

}

.conter-total{
  color:#fff!important;
  background-color: #444;
  padding: 4px;
  border-radius: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 10px!important;
  
}
.conter-mono{
  font-size: 10px!important;
}
.conter-color{
  font-size: 10px!important;
}
.label-msg-painel{
  color:#006064!important;
  border:solid 1px #81d4fa;
  padding: 4px;
  border-radius: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 10px;
}

.span-link{
  color:#1769aa!important;
  cursor: pointer;
  font-size: 10px!important;
}
.span-link:hover{
  font-weight: bold!important;
}

.container-buttons{
  display: flex;
  justify-content: space-around;
}
.container-buttons button{
  width: 48%;
  box-shadow: none;
  font-size: 11px;
}
.MuiButton-contained{
  box-shadow: none!important;
}


.display-linear-toner{
  display: flex;
  margin-top: 1px;
  border-radius: 5px;
  width: 150px!important;
}

.display-toner-container{
  width: 85%;
  display: flex;
  background-color: #f0f0f0;
  

 
}
.font-9{
  font-size: 9px!important;

}
.display-toner-span{
  width: 25%;
  
}
.display-toner-progress-icone{
  width: 10%;
  padding-top: 0px;
}
.display-toner-progress-nivel{
  width: 83%;
  padding: 1px;
  height: 70%;
  margin-top: 1px;


}
.display-toner-nivel{}

.display-toner-container-alert{
  width: 10%;  
  padding: 0px!important;
  font-size: 9px;
  text-align: center;
  border-radius: 5px;
}

.display-toner-container-alert span{
  padding-top: 2px;
  padding-right: 2px!important;
}

.display-toner-span{
  width: 15%;
  font-size: 9px;
  text-align: right;
}

.display-toner-progress-generico{
  height: 100%;
  background-color: #00796B;
  
 
}

.display-toner-progress-ciano{
  height: 100%;
  background-color: #81D4FA;
  
 
}
.display-toner-progress-magenta{
  height: 100%;
  background-color: #F48FB1;
  

}
.display-toner-progress-amarelo{
  height: 100%;
  background-color: #FFE082;
  
  
}
.display-toner-progress-preto{
  height: 100%;
  background-color: #000;
  
  
}
.detalhes-contadores{
  font-size: 9px!important;
  text-align: center!important;
}


.detalhes-contadores .MuiTypography-subtitle1{
  font-size: 11px!important;
}

.detalhes-contadores .MuiTypography-caption{
  font-size: 10px!important;
}

.detalhes-nivel{
  text-align: center!important;
  padding-left: 20px;
}

#drawer-container{
  width: 500px;
  padding-top: 100px;
  padding: 70px;
}

.button-entrar{
  top: -5px!important;
  background-color: transparent!important;
  font-weight: bold!important;
  color :orange!important;
  border-radius: 25px!important;
text-shadow: 0px 0px 25px #fff;
  padding-top: 2px!important;
  padding-bottom: 2px!important;
}



.button-entrar:hover{
  margin-top: -5px!important;
  background-color: orange!important;
  border: solid 1px orange!important;
  color :#fff!important;
}

.button-download{
  margin-top: -5px!important;
  background-color: transparent!important;
  font-weight: bold!important;
  color :#fff!important;
  border-radius: 25px!important;
text-shadow: 0px 0px 25px #fff;
  padding-top: 2px!important;
  padding-bottom: 2px!important;
}
.button-download:hover{
  margin-top: -5px!important;
  background-color: #512da8!important;
  border: solid 1px #512da8!important;
  color :#fff!important;
}


#div-container-pmc{
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5px;
}
#div-container-pmc #div-imagem{
  width: 40%;
}
#div-container-pmc #div-imagem img{
  width: 100%;
}
#div-container-pmc #div-texto{
  width: 60%;
}

#div-container-contato{
  text-align: center;
  padding: 5px;

  padding-left: 30%;
  padding-right: 30%;
  height: 700px;
  background-color: #fff!important;
  
}
.font-small{

  font-size: 9px!important;
}
#footer-rodape{
  width: 100%;
  height: 300px;
  background-color:#283593 ;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 10%;
  padding-right: 10%;
}
.footer-container-flex{
  display: flex;
  color : #fff;
}

.div-footer-logo{
  width: 40%;
}
.div-footer-texto{
  width: 60%;
}

.MuiTab-wrapper{
  font-size: 10px!important;
}

#div-dashboard-full{
  background-color: #f8f9fa;
  width: 100%!important;
  margin-top: 130px;
  padding: 20px;
  min-height: 100vh;
}
#div-dashboard-container{
  display: flex;
  margin-top: 20px;
}
.container-grafico{
  display: flex;
  height: 230px;
  width: 100%;

  text-align: center;
}
.container-status-graficos{
  width: 20%;  

}
.container-status-dca{
  width: 20%;
  margin-left: 2%;
  
  background-color: #fff;

  border-radius: 5px;
}
.container-status-equipamentos{
  width: 25%;
  margin-left: 2%;
  
  background-color: #fff;

  border-radius: 5px;
}
.container-status-equipamentos .container-status-dca-grafico{
  width: 100%;
  display: flex;
  
}
.container-status-chamado{
  width: 30%;
  margin-left: 2%;
background-color: #fff;
  border-radius: 5px;
}
.container-status-dca-grafico{
  width: 100%;
  height: 160px;
  text-align: center;
  
}
.container-chamados-dashboard{
  
  width: 100%;
  margin-left: 0px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0px;
  
}
.texto-limitado{
  width: 100%!important;
  
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  
}

.texto-overflow{
  width: 100%!important;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  
}



.MuiFormControl-root Label{
  color:#000!important;
  
}


.MuiInputBase-input{
  background-color: #fff!important;
  border-radius: 5px;
  border: solid ;
  color:#444!important;
  padding-left: 10px!important;

}
input .MuiSelect-selec{
  background-color: #f5f5f5!important;
}


.card-dash-equipamento-container-main{
  margin-left: 5px;
  background-color: #fff!important;
  border-radius: 5px;

font-size: 9px!important;
  padding: 5px;
  width: 15%!important;
 
  margin-top: 10px;
  
}

.card-dash-equipamento-container-main:hover{

  border: solid 1px #6c757d!important;
  box-shadow: 0px 0px 5px #ccc;
  cursor: pointer;
}
.card-coleta-dash{

  background-color: #fff;
  
  margin-top: 5px;
  border-radius: 5px;
  padding: 5px;
  display: flex;
}
.card-error{
 border: solid 1px #ef9a9a;
}

.card-warning{
  border: solid 1px #ffc107;
 }
.card-dash-equipamento-container-toner{
  display: flex;
}
.card-dash-equipamento-container-toner .container-toner{
  width: 24%;
  margin-left: 1px;
}
.alert-flex{
  display: flex!important;
 
  width: 100%;
}
.alert-relogio{
  position: absolute;
  margin-left: 380px;
  margin-top: -10px;
}

.recharts-wrapper{
  width: 100%!important;
}

.display-xl{
  font-size: 7.3rem!important;
}

.display-gg{
  font-size: 4.7rem;
}


.alert-default{
  background-color:rgba(255, 255, 255, 0.5)!important;
  color:#000!important;
  font-size: 10px!important;
}

.btn-ativo{

  color:#00695c;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.iconAlert-g{
  font-size: 22px!important;
}
.incon-large{
  font-size: 32px!important;
}

.div-flex-full{
  height: 100%;
  width: 100%;
  
  display: flex;
}


.drop-filtro{
  background-color: #f8f9fa!important;
  color:#444!important;
  border-radius: 25px!important;
  font-size: 9px!important;
  border:solid 1px #ccc!important;
  margin-left: 5px!important;
}
.drop-filtro:hover{

  border:solid 1px #444!important;

}

.dragged .item {
  opacity: 0.25;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #ffffff;
  cursor: grabbing;
  border: solid 1px #000!important;
  border: dashed 1px #ccc!important;
 
  
  
}
.floating .listGroup-item-atual {
  background-color: #ffffff;
  font-size: 16px!important;
  font-weight: bold;
  border: solid 1px #000!important;
  cursor: move;
  
 
}

.floating .item {
  background-color: #ffffff;
  box-shadow: 0 4px 20px #000;
  border: solid 1px #000!important;
  
 
}
.item:hover{
  border: dashed 1px #ccc!important;
}
.listGroup-item{
  border: dashed 1px #ccc;
  border-radius: 3px;
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 3px;
  background-color: #fff;
  cursor: pointer;
  font-size: 11px;
  display: flex;
}

.listGroup-item-header{
  border: solid 1px #ccc;
  border-radius: 3px;
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 3px;
  background-color: #fff;
  cursor: pointer;
  font-size: 11px;
  display: flex;
}

.listGroup-item-atual{
  border: solid 1px #455a64;
  border-radius: 3px;
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 3px;
  background-color: #fff;
  color:#000;
  cursor: pointer;
  font-size: 11px;
  display: flex;
}

input, select, textarea{
  color:#000!important;
}

#overlay-mapa-regiao{
  position: fixed;
  z-index: 99999999!important;
  top: 155px;
  left: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 5px #444;
  width: 290px;
  height: 450px;
  padding: 10px;
  border-radius: 5px;
}
.div-container-btn-relatorio{
  width: 98% ;
  text-align: center;
  
  border-radius: 5px;
  min-height: 250px;
  padding: 5px;
 background-image: linear-gradient(to top, transparent, #EFEBE9);
}
.div-container-btn-relatorio:hover{
  box-shadow: 0px -10px 15px #ECEFF1;
  
  cursor: pointer;
  margin-top: -10;
  scale: 1.1;

}

.div-container-btn-relatorio-icon:hover span{
  color:red;
}
.link-geral{
  color:#00f;
}

.link-geral:hover{
  cursor: pointer;
}
.linha-coleta-alerta{
  border-left: solid 1px red!important;
  border-radius: 10px!important;
  color:red!important;
}


@keyframes float{
	0%,100%{
		box-shadow:0 5px 15px 0 rgba(0,0,0,.6);
		transform:translatey(0)
	}
	50%{
		box-shadow:0 25px 15px 0 rgba(0,0,0,.2);
		transform:translatey(-20px)
	}
}
.efeito-flutuante img {
  animation: float 6s ease-in-out infinite;
  position: absolute;
}
.testimonial-title{
  text-align: center;
}

.testimonial-container-main{
  display: flex;
 
}
.testinonial-img-container{
  width: 50%;

}
.testinonial-img-container img{
  width: 100%;
  
}
.testinonial-text-container{
  padding-left: 2px;
  padding-top: 5px;
  width: 50%;

}
.testinonial-text-container ul{
  margin-left: 10px!important;
  font-size: 12px;
}

#testemonial .carousel-indicators li{
  background-color: #444!important;
}


#testemonial .carousel-indicators .active{
  background-color: #fdd835!important;
}

#testemonial:hover .carousel-control-next{
  border: solid 1px #444!important;
  background-color: #444!important;  
}


#testemonial:hover .carousel-control-prev{
  border: solid 1px #444!important;
  background-color: #444!important;
}
.line-emp{
  background-color: #fff!important;
}
.line-emp:hover{
  box-shadow: 0px 0px 10px #ccc;
  background-color: #37474f!important;
  color:#fff!important;
  border: solid 1px #37474f!important;
}


.img-home-flutter{
  position: absolute;
  width: 150px!important;
  margin-left: -25%;
  top: 200px;
  animation: go-back 5s infinite;
  
}
@keyframes go-back {
  0%,100%{
	
		transform:translatey(0)
	}
	50%{
	
		transform:translatey(-30px)
	}
}
.efeito-flutuante{
  animation: go-back 5s infinite;
}
.span-novo-item-coleta{
  font-size: 8px!important;
  background-color: green;
  color:#fff;
  border-radius: 3px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 2px;
  padding-top: 1px;
}

.div-badge-info{
  
}



#div-teste-background{
  background-color: red;
  width: 100%;
  height: 100vh!important;
}
.container-main-default{
  background-color: #fff!important;
  border-radius: 3px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
 
}
.font-10{
  font-size: 10px!important;
}
.vertical-timeline-element-date{
  font-size: 10px!important;
  color: #000;
}
.noPadding{
  padding: 0px!important;
  
  margin-top: -8px;
}
