#menu-principalv2{
	background-color: red;
	width: 80px;
	min-height: 100vh;
	padding: 5px;
	padding-left: 3px;
	padding-top: 10px;
	position: fixed;
	top: 90px;
	left: 0px;
}
.menu-principalv2-item-container{
	width: 100%;
	height: 60px;
	background-color: lime;
}
.menu-principalv2-item-container:hover{
	width: 500px;
	z-index: 9999999999999999999;
}