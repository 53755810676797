 /* The navigation menu */
 .navbar {
    
    background-color: #fff;
    width: 100%;
    position: absolute;
    justify-content: flex-start;
    align-items: flex-start;
    
  }
  
  /* Navigation links */
  .navbar a {
    float: center;
    font-size: 12px;
    color: #333;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
   
  }

  
  /* The subnavigation menu */
  .subnav {
    float: left;
    overflow: hidden;
    color: #333;
    
  }

 
  /* Subnav button */
  .subnav .subnavbtn {
    font-size: 12px;
    border: none;
    outline: none;
    color: #333;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
   
    
  }
  
  /* Add a red background color to navigation links on hover */
  .navbar a:hover, .subnav:hover .subnavbtn {
    color:#fff;
    background-color: #eeeeee;
    color:#e65100;

  }

  /* Style the subnav content - positioned absolute */
  .subnav-content {
    display: none;
    padding-top: 20px;
      color:#37474f;
      height: 90px!important;
      z-index: 1!important;

  }

  
  /* Style the subnav links */
  .subnav-content a {

    color: #37474f;
  }
  /* Add a grey background color on hover */
  .subnav-content a:hover {
    color:#e65100;
   
    
  }
  
  /* When you move the mouse over the subnav container, open the subnav content */
  .subnav:hover .subnav-content {
    display: block;
    
  } 

  .div-icon-menu{
      display: inline;
  }


  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  
    text-align: left;
    }


    ul li {
      position: relative;
      }


      li ul {
        position: absolute;
        left: 45px;
        top: 0;
        display: none;
        }

  ul li a {
    display: block;
    text-decoration: none;
    color: #E2144A;

    
  }
  li:hover ul { display: block; }

  .sub-menu-nav a{
    width: 100%!important;
    height: 20px!important;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    text-align: left;
    margin-top: -55px;
  }


  .menu-nivel1 a{
    
    padding: 0px;
  }